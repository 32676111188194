<template>
    <v-main>
        <Header/>
        <div class="section6">

       <v-row>
                <v-col>
                    <div class="aurora2">

        <v-img max-height="30em" min-height="20em" contain :src="require('../assets/Resized Pictures/_DSC4893.webp')">
         <!-- <v-card-title class="white--text" style="margin-top:5em;"  primary-title>
             <h1>
             Mobile Development
             </h1>
         </v-card-title> -->
         </v-img> 
             </div>
                </v-col>
            </v-row>
        
        <v-container>
           <v-row>
            <v-card-title style="margin-bottom:1em" primary-title>  
                  <h2>
                      Mobile Apps Development
                  </h2>
            <br>
            </v-card-title>
                   <div class="space">
               <v-col>
                   <h2>
                       Reach Your Audience Anywhere
                       </h2>
                       

        <p> 
           Mobile application development services market is growing at an increasing pace. To be able to keep your business
          and product relevant to the market expectations you need to go with something that adds to the power of mobility to your users. <br>

             At The Code Effect mobile development we aim at delivering just that – with highly resourceful, cost effective
             Premium Mobile experiences Innovation Experts Committed To App Development Excellence! and authentic mobile app development solutions that know the pulse of your user.
 </p>
               </v-col>
                   </div>
           </v-row>
        </v-container>
         <v-container style="margin-top:2em">
            <v-row>
                <v-col>

            <!-- <v-card-title class="justify-center" >
            
        <h4 >
            Services
        </h4>
            </v-card-title> -->
        <h3> How does a mobile app boost my business?
             More than half of your target audience uses their mobile device as their primary means
              of navigating digital platforms. By reaching them wherever they are,
             you can boost your leads and therefore your conversions.
        </h3>
                </v-col>
            </v-row>
        </v-container>



        <MobileCards/>
       
       
       
       
       
        <v-container>
            <v-row>
              <div class="space">
                <v-col>
                    <p>As a top tailored mobile app development company,
                         we craft scalable Mobile  apps that not only help achieve your business goals 
                         and offer great user experiences,
                          but also maintain processes that are <b>time-efficient </b> and <b> cost-effective. </b>
                               </p>
                </v-col>
              </div>
            </v-row>

            <!-- customer benifits -->
 
            <v-row class="justify-center">
                    <!-- <v-col>
                        <div class="round">

                        <v-img v-tilt="{glare:true, reverse: true}" :src="require('../assets/samuel-ferrara-uOi3lg8fGl4-unsplash.jpg')" height="200px" width="200px"></v-img>
                        </div>
                        <div class="round">
                            <v-img v-tilt="{glare:true, reverse: true}" :src="require('../assets/fish Website Pictures/caspar-camille-rubin-89xuP-XmyrA-unsplash.jpg')" height="300px" width="200px"></v-img>
                        </div>
                    </v-col> -->
                <v-col cols="12" lg="6" md="8" sm="8">
                        <v-card-title  style="word-break: normal; margin-top:1.5em;">
                    <h2>
                    We ensure your App will be:
                    </h2>
                    </v-card-title> 
                <!-- <div class="space" stylße="background-color:rgba(0,0,255,0.02);"> -->
                <div class="aurora2">
                    <v-card-text  >
            
                        <ul >
                            <li>
                               <p>
                                    Lightening Fast
                                   </p>
                                    </li>
                        <li>
                            <p>
                        Beautiful User interface
                            </p>
                        </li>
                        <li>
                              <p>
                        User Friendly
                          </p>
                        </li>
                        <li>
                              <p>
                        Secure
                            </p>
                            </li>
                        <li>
                              <p>
                       Reliable
                         </p>
                            </li>
                        </ul>
                        </v-card-text>   
                </div>
                <!-- </div> -->
                    </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col>

            
        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        Technologies Used:
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="4" md="4" sm="4" v-for="item in items" :key="item.id">
                    <div class="space" v-tilt="{glare:true, reverse: true}">
                        <v-lazy>

                    <v-img class="icon"  :max-height="height" :max-width="width"   :src='item.icon'>
                        </v-img>
                        </v-lazy>
                    </div>
                </v-col>
            </v-row>
        </v-container>
                </v-col>
                <v-col>

        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        We're experienced in building:
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            
        <!-- </v-container> -->
            <v-row>
                    <div class="space"  >
                <v-col>
                        <ul>
                            <li>
                                <p>
                                Ecommerce Mobile Apps
                                </p>
                            </li>
                            <li>
                                <p>
                                E-Learning Solutions
                                </p>
                            </li>
                            <li>
                                <p>
                                Delivery Apps
                                </p>
                            </li>
                            <li>
                                <p>
                                Social media Apps
                                </p>
                            </li>
                            <li>
                                <p>
                                Health and Fitness Mobile Applications
                                </p>
                            </li>
                            <li>
                                <p>
                                Transportation Apps
                                </p>
                            </li>
                                                      
                        </ul>
                </v-col>
                    </div>
            </v-row>
        </v-container>

                </v-col>
    </v-row>
        </v-container>



         <Footer/>   
    </div>

    </v-main>
</template>
<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import MobileCards from '../components/MobileCards.vue'

export default {
  components: { MobileCards, Header,Footer },
    name: 'System',
    data() {
        return {
            items:[
          {
            id:1,
            icon: require('../assets/icons/dart.svg'),
            // color:'orange darken-2'
          },
          {
            id:2,
            icon: require('../assets/icons/Flutter.svg'),
            color:'blue-grey darken-2'
          },
          {
            id:3,
            icon: require('../assets/icons/JS.svg'),
            color:'orange darken-2'
          },
          {
            id:4,
            icon: require('../assets/icons/java.svg'),
            color:'teal'
          },
          {
            id:5,
            icon: require('../assets/icons/Kotlin.svg'),
            color:'primary'
          },
          {
            id:6,
            icon: require('../assets/icons/Vue.svg'),
            color:'primary'
          },
          {
            id:7,
            icon: require('../assets/icons/React.svg'),
            color:'primary'
          },
          {
            id:8,
            icon: require('../assets/icons/Swift.svg'),
            color:'primary'
          }
        ]
            
        }
    },
    computed: {
         height() {
        if (this.$vuetify.breakpoint.name >='xs'){
            return '5em'
        }else{
            return '10em'
        }
    },
    width() {
        if (this.$vuetify.breakpoint.name >= 'xs'){
            return '5em'
        }else{
            return '10em'
        }
    },
 },
    
 metaInfo: {
      
    title: 'Mobile App Development Services',
     meta: [{
        vmid: 'description',
        name: 'description',
      content: 'Mobile App Development Company In Sudan. The Code Effect offers a variety of Mobile App Services. Android Apps, iOS App Development, and Cross-Platform App Development Services. eCommerce Apps, Delivery Apps,Uber like Apps and Education Solutions.'
     }]
    },
    
}
</script>
<style lang="postcss">
/* List */
 @import '../sass/list.sass'
</style>